import React from 'react';
import { object, array, string } from 'prop-types';
import { ReferralProgram } from '../../components/ReferralProgram';
import { ReferralProgramProps } from '../../../lib/interfaces';

const ReferralProgramPage = (props: ReferralProgramProps) => {
    const {
        user,
        referralTable,
        socialLinks,
        previewEmail,
        inviteLink
    } = props;

    return (
        <ReferralProgram user={user}
            referralTable={referralTable}
            socialLinks={socialLinks}
            previewEmail={previewEmail}
            inviteLink={inviteLink}/>
    );
};

ReferralProgramPage.propTypes = {
    user: object,
    referralTable: array,
    socialLinks: object,
    previewEmail: string,
    inviteLink: string
};

export default ReferralProgramPage;
