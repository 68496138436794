import React from 'react';
import classnames from 'classnames';
import { string, func, InferProps } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { useFacebookSdk } from '../../../hooks/useFacebookSdk';

const Icon = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="21px" viewBox="0 0 10 21"> <g id="Referral-Page.-Logged-In"><g id="MD" transform="translate(-576.000000, -679.000000)"><g id="Group-15-Copy" transform="translate(557.000000, 665.000000)"><path id="facebook-icon-copy" fill="#3D5899" d="M21.3,20.9H19v3.6h2.3V35h4.4V24.4h3l0.3-3.5h-3.4c0,0,0-1.3,0-2c0-0.8,0.2-1.2,1-1.2c0.7,0,2.4,0,2.4,0l0-3.7c0,0-2.5,0-3,0c-3.2,0-4.7,1.4-4.7,4C21.3,20.3,21.3,20.9,21.3,20.9z"/></g></g></g></svg>
);

const propTypes = {
    className: string,
    titleClassName: string,
    onClick: func,
    onPost: func,
    link: string.isRequired
};

export const Facebook = (props: InferProps<typeof propTypes>) => {
    const {
        className,
        titleClassName,
        onClick,
        onPost,
        link
    } = props;
    const { sdk } = useFacebookSdk();

    const onIconClick = (event) => {
        event.preventDefault();

        const params = {
            method: 'share',
            href: link
        };
        sdk?.ui(params, () => {
            onPost?.();
        });

        onClick?.();
    };

    return (
        <div className={classnames(className)}
            onClick={onIconClick}>
            <Icon/>
            <b className={classnames(titleClassName)}>
                <I18n k="referral_program_perseus.invite.social.facebook"/>
            </b>
        </div>
    );
};

Facebook.propTypes = propTypes;
