import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { string, func } from 'prop-types';
import { Input, Button } from '@fiverr-private/fit';
import { translate, I18n } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { track as mixpanelTrack } from '../../../lib/mixpanel';
import { PreviewEmail } from '../PreviewEmail';
import { sendReferrals } from '../../../lib/sendReferral';
import styles from './index.module.scss';

const EMAIL_REGEX = /^([a-zA-Z0-9.\-+_])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const NOTIFICATION_TYPES = {
    INFO: 'info',
    ALERT: 'alert'
};
const NOTIFICATION_MESSAGES = {
    EMAIL_INFO: 'referral_program_perseus.invite.email.separate',
    EMAIL_REGEX_INVALID: 'referral_program_perseus.invite.email.regex_invalid',
    EMAIL_SENT: 'referral_program_perseus.invite.email.email_sent',
    EMAIL_EXIST: 'referral_program_perseus.invite.email.email_exist',
    SEND_REFERRALS_FAILED: 'referral_program_perseus.invite.email.send_referrals_failed'
};

export const InviteByMail = (props) => {
    const { previewEmail, className } = props;
    const emailRef = useRef();
    const [notification, setNotification] = useState({
        type: NOTIFICATION_TYPES.INFO,
        message: NOTIFICATION_MESSAGES.EMAIL_INFO
    });
    const [loading, setLoading] = useState(false);

    const onSendClick = () => {
        const email = emailRef.current.value;
        const emails = email.split(',');

        mixpanelTrack('Referral Page Inviter - Send Email Button - Click', { 'Emails': emails });
    };

    const ModalTrigger = (props) => {
        const { open } = props;

        const onPreviewClick = () => {
            open();
            mixpanelTrack('Referral Page Inviter - Preview Email  Button - Click');
        };

        return (
            <a className={styles.emailPreview}
                onClick={onPreviewClick}>
                {translate('referral_program_perseus.invite.email.preview')}
            </a>
        );
    };

    ModalTrigger.propTypes = {
        open: func
    };

    const showNotification = (message, type = 'info') => {
        setNotification({
            type,
            message
        });

        setTimeout(resetNofication, 3000);
    };

    const resetNofication = () => {
        setNotification({
            type: NOTIFICATION_TYPES.INFO,
            message: NOTIFICATION_MESSAGES.EMAIL_INFO
        });
    };

    const onSubmit = async(event) => {
        event.preventDefault();

        const emails = emailRef.current.value
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email);

        if (!emails.length > 0) {
            return;
        }

        const emailsRegexValid = emails
            .every((email) => EMAIL_REGEX.test(email));

        if (!emailsRegexValid) {
            showNotification(NOTIFICATION_MESSAGES.EMAIL_REGEX_INVALID, NOTIFICATION_TYPES.ALERT);

            return;
        }

        try {
            setLoading(true);

            const data = await sendReferrals(emails);

            setLoading(false);

            if (data.warning) {
                showNotification(NOTIFICATION_MESSAGES.EMAIL_EXIST, NOTIFICATION_TYPES.INFO);

                return;
            }

            emailRef.current.value = '';
            showNotification(NOTIFICATION_MESSAGES.EMAIL_SENT, NOTIFICATION_TYPES.INFO);
        } catch (error) {
            const {
                userGuid,
                userId,
                pageCtxId
            } = getContext();

            error.description = 'Failed on send referrals';
            logger.error(error, {
                userGuid,
                userId,
                pageCtxId
            });

            showNotification(NOTIFICATION_MESSAGES.SEND_REFERRALS_FAILED, NOTIFICATION_TYPES.ALERT);
        }
    };

    return (
        <div className={className}>
            <form className={styles.form}
                onSubmit={onSubmit}>
                <div className={styles.email}>
                    <Input className={styles.emailInput}
                        inputClassName={classnames(styles.inputTag, { [styles.inputTagAlert]: notification.type === NOTIFICATION_TYPES.ALERT })}
                        ref={emailRef}
                        autoComplete="off"
                        placeholder={translate('referral_program_perseus.invite.email.add_email')}/>
                    <div className={styles.notification}>
                        <I18n k={notification.message}/>
                    </div>
                </div>
                <div className={styles.submit}>
                    <Button className={styles.submitButton}
                        type="submit"
                        onClick={onSendClick}
                        disabled={loading}>
                        {translate('referral_program_perseus.invite.email.send')}
                    </Button>
                    {previewEmail && <div className={styles.preview}>
                        <PreviewEmail previewEmail={previewEmail} trigger={ModalTrigger}/>
                    </div>}
                </div>
            </form>
        </div>
    );
};

InviteByMail.propTypes = {
    previewEmail: string,
    className: string
};
