import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { formatCurrency } from '../../../lib/formatCurrency';
import { formatPercentage } from '../../../lib/formatPercentage';
import {
    INVITEE_DISCOUNT_PERCENT,
    INVITER_REWARD_PERCENT,
    INVITE_BONUS_MAX_AMOUNT
} from '../../../constants';

import './index.scss';

export const StepsToInvite = () => {
    const i18nSteps = 'referral_program_perseus.steps_new';

    return (
        <div className="page-section steps-to-invite">
            <h2>{translate(`${i18nSteps}.title`)}</h2>
            <ul>
                <li>
                    <p>{translate(`${i18nSteps}.step1`)}</p>
                </li>
                <li>
                    <p>{translate(`${i18nSteps}.step2`, {
                        params: {
                            invitee_discount_percent: formatPercentage(INVITEE_DISCOUNT_PERCENT)
                        }
                    })}</p>
                </li>
                <li>
                    <p>{translate(`${i18nSteps}.step3`, {
                        params: {
                            inviter_reward_percent: formatPercentage(INVITER_REWARD_PERCENT),
                            invite_bonus_max_amount: formatCurrency(INVITE_BONUS_MAX_AMOUNT)
                        }
                    })}</p>
                </li>
            </ul>
        </div>
    );
};

StepsToInvite.propTypes = {};
