import React from 'react';
import { object, string } from 'prop-types';
import { InviteByMail } from '../InviteByMail';
import { CopyInvite } from '../CopyInvite';
import { Social } from '../Social';
import styles from './index.module.scss';

export const Invite = (props) => {
    const { socialLinks, previewEmail, inviteLink } = props;

    return (
        <div className={styles.invite}>
            <InviteByMail className={styles.inviteByEmail}
                previewEmail={previewEmail}/>
            <div className={styles.copySocial}>
                <CopyInvite className={styles.copyInvite}
                    inviteLink={inviteLink}/>
                <Social className={styles.socialShare}
                    socialLinks={socialLinks}/>
            </div>
        </div>
    );
};

Invite.propTypes = {
    socialLinks: object,
    previewEmail: string,
    inviteLink: string
};
