import { useEffect, useState } from 'react';
import { logger } from '@fiverr-private/obs';

const SDK_SRC = '//connect.facebook.net/en_US/sdk.js';
const APP_ID = '202127659076';
const SDK_VERSION = 'v15.0';

interface FB {
    init: (params: {
        appId: string,
        status: boolean,
        cookie: boolean,
        xfbml: boolean,
        version: string,
    }) => void;
    ui: (params: {
        method: string,
        href: string,
    }, onPost: () => void) => void;
}

declare global {
    const FB: FB;
    function fbAsyncInit(): void;
}

const injectSDK = () => {
    const script = document.createElement('script');

    script.src = SDK_SRC;

    return new Promise<FB>((resolve) => {
        window.fbAsyncInit = () => {
            FB.init({
                appId: APP_ID,
                status: true,
                cookie: true,
                xfbml: true,
                version: SDK_VERSION
            });

            resolve(FB);
        };

        document.head.appendChild(script);
    });
};

export const useFacebookSdk = () => {
    const [sdk, setSdk] = useState<FB>();

    useEffect(() => {
        injectSDK()
            .then((sdk) => setSdk(sdk))
            .catch(() => {
                logger.error('Failed to fetch facebook sdk');
            });
    }, []);

    return {
        sdk
    };
};
