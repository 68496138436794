import React from 'react';
import { string, func } from 'prop-types';
import classnames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import { Modal } from '@fiverr-private/orca';

import styles from './index.module.scss';

export const PreviewEmail = (props) => {
    const {
        previewEmail,
        trigger
    } = props;

    return (
        <Modal trigger={trigger}
            className={classnames(styles.modal, styles.desktop)}>
            <Modal.Layout>
                <Modal.Header>
                    <div>{translate('referral_program_perseus.invite.email.preview')}</div>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: previewEmail }}/>
                </Modal.Body>
            </Modal.Layout>
        </Modal>
    );
};

PreviewEmail.propTypes = {
    previewEmail: string,
    trigger: func
};
