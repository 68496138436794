import React from 'react';
import classnames from 'classnames';
import { string, func, InferProps } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';

const Icon = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18px" height="14px" viewBox="0 0 18 14"><g id="Referral-Page.-Logged-In"><g id="SM" transform="translate(-397.000000, -643.000000)"><g id="Group-14" transform="translate(382.000000, 626.000000)"><g id="Twitter_bird_logo_2012_white-Copy" transform="translate(15.000000, 17.000000)"><path id="path5" fill="#1DA1F3" d="M17.2,1.7c-0.6,0.3-1.3,0.5-2,0.6c0.7-0.4,1.3-1.1,1.5-1.9c-0.7,0.4-1.4,0.7-2.2,0.8C13.9,0.5,13,0.1,12,0.1c-1.9,0-3.5,1.6-3.5,3.5c0,0.3,0,0.5,0.1,0.8C5.6,4.2,3,2.9,1.3,0.7C1,1.3,0.8,1.9,0.8,2.5c0,1.2,0.6,2.3,1.6,2.9C1.8,5.4,1.3,5.2,0.8,5c0,0,0,0,0,0c0,1.7,1.2,3.1,2.8,3.4C3.3,8.5,3,8.6,2.7,8.6c-0.2,0-0.4,0-0.7-0.1c0.4,1.4,1.7,2.4,3.3,2.4c-1.2,0.9-2.7,1.5-4.4,1.5c-0.3,0-0.6,0-0.8,0c1.6,1,3.4,1.6,5.4,1.6c6.5,0,10-5.3,10-9.9c0-0.2,0-0.3,0-0.5C16.2,3.1,16.8,2.4,17.2,1.7z"/></g></g></g></g></svg>
);

const propTypes = {
    className: string,
    titleClassName: string,
    onClick: func,
    link: string.isRequired
};

export const Twitter = (props: InferProps<typeof propTypes>) => {
    const {
        className,
        titleClassName,
        onClick,
        link
    } = props;

    const onIconClick = () => {
        onClick?.();
    };

    return (
        <a className={classnames(className)}
            href={link}
            target="twitter"
            onClick={onIconClick}>
            <Icon/>
            <b className={classnames(titleClassName)}>
                <I18n k="referral_program_perseus.invite.social.twitter"/>
            </b>
        </a>
    );
};

Twitter.propTypes = propTypes;
