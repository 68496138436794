/**
 * The referral program invitee discount percent.
 * @type {number}
 */
export const INVITEE_DISCOUNT_PERCENT = 0.1;

/**
  * The referral program inviter reward percent (invitee's first order).
  * @type {number}
  */
export const INVITER_REWARD_PERCENT = 0.1;

/**
  * The invite bonus maximum amount (in USD).
  * @type {number}
  */
export const INVITE_BONUS_MAX_AMOUNT = 100;

/**
  * The default currency used in the package.
  * @type {string}
  */
export const DEFAULT_CURRENCY = 'USD';

/**
 * Send referral api path
 */
export const SEND_REFERRAL_PATH = '/referral_program/send_referrals';
