import React from 'react';
import classnames from 'classnames';
import { shape, string } from 'prop-types';
import { Button } from '@fiverr-private/fit';
import { translate, I18n } from '@fiverr-private/i18n-react';
import UserImage from '../UserImage';
import { INVITE_BONUS_MAX_AMOUNT } from '../../../constants';
import { formatCurrency } from '../../../lib/formatCurrency';
import { User } from '../../../lib/interfaces';
import { SIGNUP_CLASS_NAME } from './constants';

import styles from './index.module.scss';

import './index.scss';

interface PageHeaderProps {
    user?: User;
}

export const PageHeader = (props: PageHeaderProps) => {
    const { user } = props;
    const isGuest = !user;
    const sectionClass = !isGuest ? 'page-section referral-page-header js-referral-program logged-in' : 'page-section referral-page-header';

    // @ts-expect-error Button does not define SIZES property
    const size = Button.SIZES.LARGE;

    return (
        <div className={sectionClass}>
            {user && <UserImage username={user.username}
                userPhoto={user.userPhoto}/>}
            <h1>
                <I18n k={'referral_program_perseus.page_header.title'}
                    params={{ invite_bonus_max_amount: formatCurrency(INVITE_BONUS_MAX_AMOUNT) }}/>
            </h1>
            <p role="doc-subtitle">
                <I18n k={'referral_program_perseus.page_header.sub_title'}/>
            </p>
            {isGuest && <Button className={classnames(SIGNUP_CLASS_NAME, styles.join)}
                href={translate('referral_program_perseus.page_header.joinUrl')}
                size={size}>
                <I18n k={'referral_program_perseus.page_header.cta'}/>
            </Button>}
        </div>
    );
};

PageHeader.propTypes = {
    user: shape({
        username: string,
        userPhoto: string
    })
};
