import axios, { AxiosResponse } from 'axios';
import { SEND_REFERRAL_PATH } from '../../constants';
import { SEND_REFERRAL_PAYLOAD } from '../interfaces';

/**
 * Send referral type
 */
const REFERRAL_TYPE = 'inline';

export const sendReferrals = async(emails: string[]): Promise<SEND_REFERRAL_PAYLOAD> => {
    const { data }: AxiosResponse<SEND_REFERRAL_PAYLOAD> = await axios.post(SEND_REFERRAL_PATH, {
        invitees: emails,
        referral_type: REFERRAL_TYPE
    });

    return data;
};
