import { currencyFormat as formatCurrencyBase } from '@fiverr-private/localization';
import { DEFAULT_CURRENCY } from '../constants';

const DEPRECATED_formatCurrency = (number: number, currency: string): string =>
    currency === 'USD' ? `$${number}` : `${number} ${currency}`;

/**
 *  Format currency to a Fiverr generic format
 * @param {number} number Money amount
 * @param {string} currency Currency format
 * @returns {string} Formatted amount
 */
export const formatCurrency = (number: number, currency: string = DEFAULT_CURRENCY) =>
    formatCurrencyBase(number, currency, {
        fallbackValue: DEPRECATED_formatCurrency(number, currency)
    });
