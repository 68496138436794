import React from 'react';
import { string } from 'prop-types';
import _ from 'lodash';
import { User } from '../../../lib/interfaces';

import styles from './index.module.scss';

const UserImage = (props: User) => {
    const { userPhoto, username } = props;
    const hasPhoto = _.size(userPhoto) > 0;

    return (
        <label className={styles.holder}>
            {hasPhoto && <img className={styles.image}
                src={userPhoto}
                alt={username}/>}
            {!hasPhoto && <span className={styles.withoutPhoto}>
                {username.substring(0, 1)}
            </span>}
        </label>
    );
};

UserImage.propTypes = {
    userPhoto: string,
    username: string.isRequired
};

export default UserImage;
