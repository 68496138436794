import React from 'react';
import { array } from 'prop-types';
import classNames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import { ReferralTable } from '../../../lib/interfaces';

import './index.scss';

const MAX_INVITEES_TO_SHOW = 5;

interface TrackReferralsProps {
    referralTable: ReferralTable;
}

interface TrackReferralsState {
    showInvitees: boolean;
}

export class TrackReferrals extends React.Component<TrackReferralsProps, TrackReferralsState> {
    static propTypes = {
        referralTable: array
    };

    constructor(props: TrackReferralsProps) {
        super(props);
        this.showAllReferrals = this.showAllReferrals.bind(this);
        this.state = {
            showInvitees: false
        };
    }

    showAllReferrals(){
        this.setState({
            showInvitees: !this.state.showInvitees
        });
    }

    override render() {

        const {
            referralTable
        } = this.props;

        const statusClasses = {
            sent: 'sent',
            registered: 'registered',
            granted: 'granted'
        };

        const showShowAll = (!this.state.showInvitees && referralTable.length > MAX_INVITEES_TO_SHOW);

        return (
            <div className="page-section track-referrals">
                <h2>{translate('referral_program_perseus.track_referrals.title')}</h2>
                <div className={classNames('expand-container', { show: this.state.showInvitees })}>
                    <table>
                        <thead>
                            <tr>
                                {['email', 'status', 'updated']
                                    .map((key) => (
                                        <th key={key}>
                                            <h3>
                                                {translate(`referral_program_perseus.track_referrals.${key}`)}
                                            </h3>
                                        </th>)
                                    )}
                            </tr>
                        </thead>
                        <tbody>
                            {referralTable.map((item, index) => (
                                <tr key={index}>
                                    <td><p className="table-content email">{item.email}</p></td>
                                    <td className="status">
                                        <p className={`${Object.keys(statusClasses).filter((key) => (statusClasses[key] === item.status))[0]}`}>
                                            <i/>{translate(`referral_program_perseus.track_referrals.status_title.${item.status}`)}
                                        </p>
                                    </td>
                                    <td className="updated-at">
                                        <p className="table-content ">{item.updated}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {showShowAll && <a href="#!"
                    className="show-all"
                    onClick={this.showAllReferrals}>
                    {translate('referral_program_perseus.track_referrals.show_all')}
                </a>}
            </div>
        );
    }
}
