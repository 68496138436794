import React from 'react';
import { object, string } from 'prop-types';
import { translate } from '@fiverr-private/i18n-react';
import { track as mixpanelTrack } from '../../../lib/mixpanel';
import { SocialIcon } from '../SocialIcon';
import styles from './index.module.scss';

export const Social = (props) => {
    const {
        className,
        socialLinks
    } = props;

    const socialAPIs = Object
        .keys(socialLinks)
        .map((key) => ({
            title: translate(`referral_program_perseus.invite.social.${key}`),
            className: key,
            link: socialLinks[key],
            key
        }));

    const onSocialClick = (socialAPI) => {
        mixpanelTrack('Referral Page Inviter - Social Button - Click', { 'Social Type': socialAPI.key });
    };

    return (
        <div className={className}>
            <h6 className={styles.header}>
                {translate('referral_program_perseus.invite.social.share')}
            </h6>
            <ul className={styles.socialItems}>
                {socialAPIs.map((socialAPI, index) =>
                    (socialAPI.link ? (
                        <li className={styles.socialItem}
                            key={index}>
                            <SocialIcon type={socialAPI.key}
                                title={socialAPI.title}
                                link={socialAPI.link}
                                onClick={() => onSocialClick(socialAPI)}/>
                        </li>)
                        : (null)
                    )
                )}
            </ul>
        </div>
    );
};

Social.propTypes = {
    socialLinks: object.isRequired,
    className: string
};
