import { mixpanel } from '@fiverr-private/obs';

/**
 * Wrap mixpanel track api to enrich with default properties.
 * @param event Event name
 * @param properties Event properties
 */
export const track = (event: string, properties: object = {}) => {
    mixpanel.track(event, {
        ...properties,

        /**
         * Adding version to analyse migration resutl.
         * Perseus uses v5 value and V2 package uses v2 value.
         */
        'Version': 'v5'
    });
};
