import React, { useEffect } from 'react';
import classnames from 'classnames';
import { array, string, object } from 'prop-types';
import { track as mixpanelTrack } from '../../../lib/mixpanel';
import { ReferralProgramProps } from '../../../lib/interfaces';
import { PageHeader } from '../PageHeader';
import { Invite } from '../Invite';
import { TrackReferrals } from '../TrackReferrals';
import { StepsToInvite } from '../StepsToInvite';
import { Terms } from '../Terms';
import styles from './index.module.scss';

import './index.scss';

export const ReferralProgram = (props: ReferralProgramProps) => {
    const {
        user,
        referralTable,
        socialLinks,
        previewEmail,
        inviteLink
    } = props;

    useEffect(() => {
        mixpanelTrack('Referral Page Inviter - Referral Page Inviter - View', {});
    }, []);

    return (
        <div className="referral-program-perseus">
            <PageHeader user={user}/>
            {socialLinks && inviteLink && <main className={classnames(styles.pageSection, styles.inviteHolder)}>
                <Invite socialLinks={socialLinks}
                    previewEmail={previewEmail}
                    inviteLink={inviteLink}/>
            </main>}
            {referralTable && <article aria-label="track-referrals">
                <TrackReferrals referralTable={referralTable}/>
            </article>}
            <StepsToInvite/>
            <Terms/>
        </div>
    );
};

ReferralProgram.propTypes = {
    user: object,
    referralTable: array,
    socialLinks: object,
    previewEmail: string,
    inviteLink: string
};
