import React, { Component } from 'react';
import { Button } from '@fiverr-private/fit';
import { translate } from '@fiverr-private/i18n-react';
import {
    INVITEE_DISCOUNT_PERCENT,
    INVITER_REWARD_PERCENT,
    INVITE_BONUS_MAX_AMOUNT
} from '../../../constants';
import { formatCurrency } from '../../../lib/formatCurrency';
import { formatPercentage } from '../../../lib/formatPercentage';

import './index.scss';

export class Terms extends Component {

    constructor(props) {
        super(props);

        this.toggleTermsView = this.toggleTermsView.bind(this);

        this.state = {
            showTerms: false
        };
    }

    toggleTermsView(e){
        e.preventDefault();

        this.setState({
            showTerms: !this.state.showTerms
        });
    }

    render() {
        const termsClass = this.state.showTerms ? 'full-terms show' : 'full-terms';
        const fullTermsI18nKey = 'referral_program_perseus.terms.fullTermsNew';

        return (
            <div className="page-section terms">
                <h2>{translate('referral_program_perseus.terms.title')}</h2>
                <p className="desc">{translate('referral_program_perseus.terms.desc', {
                    params: {
                        invite_bonus_max_amount: formatCurrency(INVITE_BONUS_MAX_AMOUNT)
                    }
                })}</p>
                <a href="#!" className="read" onClick={this.toggleTermsView}>{translate('referral_program_perseus.terms.read')}</a>
                <div className={termsClass}>
                    <h3>{translate(`${fullTermsI18nKey}.header`)}</h3>
                    <h4>{translate(`${fullTermsI18nKey}.sub1`)}</h4>
                    <p dangerouslySetInnerHTML={{ __html: translate(`${fullTermsI18nKey}.p1`) }}></p>
                    <h4>{translate(`${fullTermsI18nKey}.sub2`)}</h4>
                    <p>{translate(`${fullTermsI18nKey}.p2`)}</p>
                    <h4>{translate(`${fullTermsI18nKey}.sub3`)}</h4>
                    <p dangerouslySetInnerHTML={{ __html: translate(`${fullTermsI18nKey}.p3`, {
                        params: {
                            invitee_discount_percent: formatPercentage(INVITEE_DISCOUNT_PERCENT),
                            inviter_reward_percent: formatPercentage(INVITER_REWARD_PERCENT)
                        }
                    }) }}></p>
                    <h4>{translate(`${fullTermsI18nKey}.sub4`)}</h4>
                    <p>{translate(`${fullTermsI18nKey}.p4`)}</p>
                    <h4>{translate(`${fullTermsI18nKey}.sub5`)}</h4>
                    <p>{translate(`${fullTermsI18nKey}.p5`)}</p>
                    <h4>{translate(`${fullTermsI18nKey}.sub6`)}</h4>
                    <p>{translate(`${fullTermsI18nKey}.p6`)}</p>
                    <h4>{translate(`${fullTermsI18nKey}.sub7`)}</h4>
                    <p>{translate(`${fullTermsI18nKey}.p7`)}</p>
                    <p className="t-a-center p-t-20">
                        <Button color={Button.COLORS.GREY}
                            fill={Button.FILLS.GHOST}
                            onClick={this.toggleTermsView}>
                            {translate('referral_program_perseus.terms.close')}
                        </Button>
                    </p>
                </div>
            </div>
        );
    }
}

Terms.propTypes = {};
