import { NUMBER_STYLES, numberFormat } from '@fiverr-private/localization';

const DEPRECATED_formatPercentage = (number) =>
    `${number * 100}%`;

export const formatPercentage = (number) =>
    numberFormat(number, {
        style: NUMBER_STYLES.PERCENT,
        fallbackValue: DEPRECATED_formatPercentage(number)
    });
