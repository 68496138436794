import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { string, InferProps } from 'prop-types';
import { Input, Button } from '@fiverr-private/fit';
import { translate, I18n } from '@fiverr-private/i18n-react';
import { track as mixpanelTrack } from '../../../lib/mixpanel';

import styles from './index.module.scss';

const propTypes = {
    inviteLink: string.isRequired,
    className: string
};

export const CopyInvite = (props: InferProps<typeof propTypes>) => {
    const {
        inviteLink,
        className
    } = props;

    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    // @ts-expect-error Button does not define COLORS property
    const LIGHT_GREY_BUTTON = Button.COLORS.LIGHT_GREY;

    const onCopyClick = () => {
        if (inputRef.current) {
            navigator.clipboard.writeText(inputRef.current.value);
            setCopySuccess(true);
            mixpanelTrack('Referral Page Inviter - Copy Link  Button - Click');
        }
    };

    useEffect(() => {
        if (!copySuccess) {
            return;
        }

        setTimeout(() => {
            setCopySuccess(false);
        }, 2000);
    }, [copySuccess]);

    return (
        <div className={classnames(className)}>
            <h6 className={styles.header}>
                {translate('referral_program_perseus.invite.copy_link.header')}
            </h6>
            <div className={styles.copy}>
                <Input className={styles.copyInput}
                    inputClassName={styles.inputTag}
                    readOnly
                    defaultValue={inviteLink}
                    innerRef={inputRef}/>
                <Button onClick={onCopyClick}
                    className={styles.copyButton}
                    color={LIGHT_GREY_BUTTON}>
                    {translate('referral_program_perseus.invite.copy_link.copy')}
                </Button>
                {copySuccess && <span className={styles.copyNotification}>
                    <I18n k="referral_program_perseus.invite.copy_link.message"/>
                </span>}
            </div>
        </div>
    );
};

CopyInvite.propTypes = propTypes;
