import React from 'react';
import { string, func } from 'prop-types';
import classnames from 'classnames';
import { bigQuery } from '@fiverr-private/obs';
import { track as mixpanelTrack } from '../../../lib/mixpanel';
import { Facebook } from './Facebook';
import { Twitter } from './Twitter';
import styles from './index.module.scss';

const Google = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="16px" viewBox="0 0 25 16"><g id="Referral-Page.-Logged-In"><path id="_xF0D5_-copy" fill="#D14C3E" d="M15.2,7.9c0,4.4-3,7.6-7.4,7.6C3.5,15.5,0,12,0,7.7S3.5,0,7.7,0c2.1,0,3.8,0.8,5.2,2l-2.1,2c-0.6-0.5-1.6-1.2-3.1-1.2C5.1,2.8,2.9,5,2.9,7.7s2.2,4.9,4.8,4.9c3.1,0,4.2-2.2,4.4-3.3H7.7V6.6H15C15.1,7,15.2,7.4,15.2,7.9z M24.3,6.6v2.2h-2.2V11h-2.2V8.8h-2.2V6.6h2.2V4.4h2.2v2.2H24.3z"/></g></svg>
);

const Linkedin = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="17px" viewBox="0 0 16 17"><g id="Referral-Page.-Logged-In"><g id="SM" transform="translate(-529.000000, -641.000000)"><g id="Group-10" transform="translate(513.000000, 626.000000)"><path id="Shape-Copy" fill="#0677B6" d="M16,16.8c0-0.5,0.2-1,0.5-1.3c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.4c0,0.5-0.2,0.9-0.5,1.3c-0.3,0.3-0.7,0.5-1.3,0.5h0c-0.5,0-0.9-0.2-1.2-0.5C16.2,17.8,16,17.3,16,16.8zM16,32V19.9h3.4V32H16z M20.6,32h3.7v-6.6c0-0.4,0-0.7,0.1-1c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.4,1.2-0.4c1.3,0,1.9,0.9,1.9,2.7V32H32v-6.8c0-1.8-0.4-3.1-1.2-4s-1.8-1.4-3.1-1.4c-1.4,0-2.6,0.7-3.4,2v0h0l0,0v-1.7h-3.7c0,0.4,0,1.6,0,3.5C20.6,25.7,20.6,28.4,20.6,32z"/></g></g></g></svg>
);

const SOCIAL_TYPES = {
    FACEBOOK: 'facebook',
    GOOGLE: 'google',
    LINKEDIN: 'linkedin',
    TWITTER: 'twitter'
};

const Icons = {
    [SOCIAL_TYPES.FACEBOOK]: Facebook,
    [SOCIAL_TYPES.GOOGLE]: (props) => <LinkIcon icon={Google} {...props}/>,
    [SOCIAL_TYPES.LINKEDIN]: (props) => <LinkIcon icon={Linkedin} {...props}/>,
    [SOCIAL_TYPES.TWITTER]: Twitter
};

const LinkIcon = (props) => {
    const {
        className,
        titleClassName,
        icon: Icon,
        link,
        type,
        title,
        onClick
    } = props;

    return (
        <a href={link}
            target={type}
            className={className}
            onClick={onClick}>
            <Icon/>
            <b className={titleClassName}>
                {title}
            </b>
        </a>
    );
};

LinkIcon.propTypes = {
    className: string,
    titleClassName: string,
    icon: func,
    link: string,
    title: string,
    type: string,
    onClick: func
};

export const SocialIcon = (props) => {
    const {
        className,
        type,
        link,
        title,
        onClick
    } = props;

    const onIconClick = () => {
        const biEvent = {
            type: 'social_share.click',
            group: 'share_on_social_network',
            reason: type
        };
        bigQuery.send(biEvent);

        /**
         * TODO!!!
         * Need to check
         * Is redundant, have similar event
         * 'Referral Page Inviter - Social Button - Click'
         */
        const mixpanelProps = {
            Media: type
        };
        mixpanelTrack('Clicked Social Sharing', mixpanelProps);

        onClick();
    };

    const onIconPost = () => {
        const biEvent = {
            type: 'social_share.post',
            group: 'share_on_social_network',
            reason: type
        };
        bigQuery.send(biEvent);
    };

    const Icon = Icons[type];

    return (
        <Icon className={classnames(styles.socialIcon, styles[type], className)}
            titleClassName={styles.socialLabel}
            onClick={onIconClick}
            onPost={onIconPost}
            link={link}
            title={title}
            type={type}/>
    );
};

SocialIcon.propTypes = {
    className: string,
    type: string,
    link: string,
    title: string,
    onClick: func
};

